import logo from "./logo.svg";
import "./App.css";
import {useEffect,useState} from "react";
import React from "react";
import quotes from "./quotes.js";
import Modal from "react-modal";
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

function App() {
  const [imageList, setImageList] = useState([]);
  const [subreddit, setSubreddit] = useState("wallpaper");
  const [isOpen,setIsOpen] = useState(false);
  const [last,setLast] = useState('');


  //pdf to html 
  

  //get image list of 1000 images from subreddit
  const getImageListFromSubreddit = () => {
    fetch("https://www.reddit.com/r/"+subreddit+"/top.json?t=year?after="+last) 
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setLast(data.data.children[data.data.children.length-1].data.name);
        setImageList(imageList.concat(data.data.children.map((child) => child.data.url)));
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    getImageListFromSubreddit();
  }, [subreddit]);

  const [isMobile, setMobile] = useState(false);

  React.useEffect(() => {
    _isMobileScreen();
    window.addEventListener("resize", _isMobileScreen);
  }, []);

  const _isMobileScreen = () => {
    if (window.innerWidth <= 768) {
      setMobile(true);
      return;
    }
    setMobile(false);
  };

  const settings = {
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };

  return <div className="App" style={{position:'relative'}}>
    {isOpen && <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}> 
      <div className="modal-content">
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}><p style={{padding:'20px'}}>Options</p><p style={{padding:'20px'}} onClick={()=>{setIsOpen(false)}}>Close</p></div>
        <input className="input-box" type="text" placeholder="keyword" value={subreddit} onChange={(e)=>{setSubreddit(e.target.value)}}/>
        </div>
    </Modal>  
    }

    <Slider infinite={false} afterChange={(e)=>{e == imageList.length - 1 && getImageListFromSubreddit()}} {...settings} arrows={false} slidesToShow={1} slide>
      {imageList.map((image, index) => {
        return <img key={index} src={image} style={{width:'100vh',height:'100vh',objectFit:'cover'}}/>
      })}
    </Slider>
    <div style={{width:'25%',height:'25%',bottom:'0',background: 'rgba(0,0,0,0)',position:'fixed',zIndex:'0'}} onClick={()=>{setIsOpen(true)}}>

    </div>
  </div>;
}

export default App;
