import logo from "./logo.svg";
import "./App.css";
import {useEffect,useState} from "react";
import React from "react";
import quotes from "./quotes.js";
import Modal from "react-modal";
import { FullScreen, useFullScreenHandle } from "react-full-screen";

function App() {
  const [quote, setQuote] = useState("");
  const [image, setImage] = useState("");
  const [selectedFn, setSelectedFn] = useState("reddit");
  const [selectedText, setSelectedText] = useState("quote");
  const [imageList, setImageList] = useState([]);
  const [hideQuote, setHideQuote] = useState(false);
  const [hideImage, setHideImage] = useState(false);
  const [subreddit, setSubreddit] = useState("wallpaper");
  const [keyword, setKeyword] = useState("wallpaper");
  const [isOpen,setIsOpen] = useState(false);
  const handle = useFullScreenHandle();

  //GET random joke from r/jokes
  const getRandomJoke = async () => {
    const response = await fetch("https://www.reddit.com/r/jokes/random.json");
    const data = await response.json();
    const joke = data[0].data.children[0].data.title+" "+data[0].data.children[0].data.selftext;
    setQuote(joke);
  };

  const getRandomImage = () => {
    fetch("https://source.unsplash.com/random/1920x1080")
      .then((response) => {
        setImage(response.url);
        console.log(response.url);
        return response.url;
      })
    }

  //get random image from subbreddit
  const getRandomImageFromSubreddit = () => {
    fetch("https://www.reddit.com/r/"+subreddit+"/random.json")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log('here',data[0].data.children[0].data.preview.images[0].resolutions[0].url);
        setImage(data[0].data.children[0].data.url);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //get random quote from api
  const getRandomQuote = () => {
    fetch("https://api.quotable.io/random", {
      method: "GET",
    })
      .then(async (response) => {
        const data = await response.json();
        setQuote(data.content);
        console.log(data.content);
      })
    }

  //get color name from hex code
  const getColorName = (hex) => {
    fetch(`https://www.thecolorapi.com/id?hex=${hex}`)
      .then((response) => {
        return response.json();
      }).then((data) => {
        console.log(data.name.value);
  })};

  //get image by keyword
  const getImageByKeyword = (keyword) => {
    fetch(`https://source.unsplash.com/1600x900/?${keyword}`)
      .then((response) => {
        setImage(response.url);
        console.log(response.url);
        return response.url;
      })
  }

  //get random fact from api
  const getRandomFact = () => {
    fetch("https://uselessfacts.jsph.pl/random.json?language=en")
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data.text);
        setQuote(data.text);
      })
      .catch((err) => {
        console.error(err);
      });
  }

  //get image list of 1000 images from subreddit
  const getImageListFromSubreddit = () => {
    fetch(`https://www.reddit.com/r/pics/top.json?t=year?limit=None`) 
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        console.log(data);
        setImageList(data.data.children.map((child) => child.data.url));
      })
      .catch((err) => {
        console.error(err);
      });
  }

  useEffect(() => {
    if(selectedFn == 'reddit'){
      getRandomImageFromSubreddit();
    }
    else if(selectedFn == 'unsplash'){
      getImageByKeyword(keyword);
    }
    else if(selectedFn == 'unsplashrandom'){
      getRandomImage();
    }
    if(selectedText == 'quote'){
      getRandomQuote();
    }
    else if(selectedText == 'fact'){
      getRandomFact();
    }else if(selectedText == 'joke'){
      getRandomJoke();
    }
  }, [selectedFn,selectedText,keyword,subreddit]);

  const getData = () => {
    if(selectedFn == 'reddit'){
      getRandomImageFromSubreddit();
    }
    else if(selectedFn == 'unsplash'){
      getImageByKeyword(keyword);
    }
    else if(selectedFn == 'unsplashrandom'){
      getRandomImage();
    }
    if(selectedText == 'quote'){
      getRandomQuote();
    }
    else if(selectedText == 'fact'){
      getRandomFact();
    } else if(selectedText == 'joke'){
      getRandomJoke();
    }
  }

  const [isMobile, setMobile] = useState(false);

  React.useEffect(() => {
    _isMobileScreen();
    window.addEventListener("resize", _isMobileScreen);
  }, []);

  const _isMobileScreen = () => {
    if (window.innerWidth <= 768) {
      setMobile(true);
      return;
    }
    setMobile(false);
  };

  return           <FullScreen handle={handle}>
  <div className="App" style={{background:'#000'}}>
      {/* image slider populated from imagelist */}
      {/* {imageList.length > 0 && <div className="image-slider">
        <div className="image-slider-container">
          <div className="image-slider-wrapper">
            {imageList.map((image, index) => {
              return (!image.includes('redgif') && !image.includes('gifv')) && <div className="image-slider-item" key={index}>
                <img className="slider-image" src={image} alt=""/>
              </div>
            })}
          </div>
        </div>
      </div>} */}

    {/* <img src={isMobile?"https://picsum.photos/1080/1920/":"https://picsum.photos/1920/1080/"} style={{objectFit:'cover',zIndex:0,top:0,left:0 ,position:'fixed',overflow:'hidden',height:'100%',width:'100vw'}}>
    </img> */}
    {<img src={!hideImage ? image : '/blank.png'} style={{background:'#000', objectFit:'cover',zIndex:0,top:0,left:0 ,position:'fixed',overflow:'hidden',height:'100%',width:'100vw'}}>
    </img>}
    {isOpen && <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}> 
      <div className="modal-content">
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}><p style={{padding:'20px'}}>Options</p><p style={{padding:'20px'}} onClick={()=>{setIsOpen(false)}}>Close</p></div>
        <div className="option-container" style={{display:'flex',flexDirection:'column'}}>
          <select value={selectedFn} onChange={(e)=>{setSelectedFn(e.target.value)}}>
            <option value="reddit">Reddit</option>
            <option value="unsplash">Unsplash</option>
            <option value="unsplashrandom">Unsplash Random Image</option>
          </select>

          <select value={selectedText} onChange={(e)=>{setSelectedText(e.target.value)}}>
            <option value="quote">Quote</option>
            <option value="fact">Fact</option>
            <option value="joke">Joke</option>
          </select> 

          {selectedFn == 'reddit' && <input className="input-box" type="text" placeholder="subreddit" value={subreddit} onChange={(e)=>{setSubreddit(e.target.value)}}/>}
          {selectedFn == 'unsplash' && <input className="input-box" type="text" placeholder="keyword" value={keyword} onChange={(e)=>{setKeyword(e.target.value)}}/>}

          <div className="flex-row"><input type={'checkbox'} checked={hideImage} onChange={()=>{setHideImage(!hideImage)}}/> Hide Image</div>
          <div className="flex-row"><input type={'checkbox'} checked={hideQuote} onChange={()=>{setHideQuote(!hideQuote)}}/> Hide Text</div>
          <button onClick={handle.enter}>
          Enter fullscreen
          </button>
        </div>
      </div>
    </Modal>  
    }
    {!hideQuote && <div style={{zIndex:0,top:0,left:0 ,position:'absolute',width:'100%',display:'flex',flexDirection:'column',alignItems:'center',justifyContent:'center',height:'95vh'}}>
      <p className="typing-demo" style={{background:'rgba(0,0,0,0.5)',width:'50%', color:'#fff',height:'min-content', fontSize:isMobile?'15px':'30px',textAlign:'center',padding:'20px 50px'}}>{quote}</p>
    </div>}
    <div style={{width:'50%',height:'50%',bottom:'0',background: 'rgba(0,0,0,0)',position:'fixed',zIndex:'0'}} onClick={()=>{setIsOpen(true)}}>

    </div>
    <div style={{width:'50%',height:'50%',bottom:'0',right:'0',background: 'rgba(0,0,0,0)',position:'fixed',zIndex:'0'}} onClick={()=>{getData()}}>

    </div>
    <div style={{width:'50%',height:'50%',top:'0',right:'0',background: 'rgba(0,0,0,0)',position:'fixed',zIndex:'0'}} onClick={()=>{window.open(image,'_blank');}}>

    </div>
    <div style={{width:'50%',height:'50%',top:'0',left:'0',background: 'rgba(0,0,0,0)',position:'fixed',zIndex:'0'}} onClick={()=>{navigator.clipboard.writeText(quote);}}>

    </div>
  </div>
  </FullScreen>;
}

export default App;
