import "./App.css";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router, Route, Link, Routes, } from "react-router-dom";
import Quoter from "./Quoter";
import Slider from "./SliderPage";

function App() {


  return <Router>
          <Routes>
            <Route path="/" element={<Quoter />} />
            <Route path="/slider" element={<Slider />} />
            </Routes>

          </Router>;
}

export default App;
